import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import vuex from "vuex";
import elementPlus from "element-plus";
import "element-plus/dist/index.css";
import ptBr from "element-plus/es/locale/lang/pt-br";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from "axios"


// Other imports goes here
import firebase from "./helpers/firebase";
import userFunctions from "./helpers/functions/users";

let app;
app = createApp(App).use(store).use(router).use(vuex).use(elementPlus, { locale: ptBr }).use(ElementPlusIconsVue);
//.mount("#app");

firebase.auth().onAuthStateChanged(async (user) => {
  let userData;
  if (user) userData = (await new userFunctions().getUser({ uid: user.uid })).data; // this promise was slow enough to not resolve before the page loads (even though it's a really fast promise)
  if (user) store.dispatch("auth/login", { ...user, ...userData });
  else store.dispatch("auth/logout");
  // store.commit('authStateChanged', user);
  app.mount("#app");
});

axios({
  method: "post",
  // url: "http://localhost:5001/projeto-base-dm/us-central1/app/api/auth/status",
  url: "https://apps.datamachina.com.br/api/auth/status",
  withCredentials: true,
})
  .then(function (res) {
    console.log("axios res", res);
  })
  .catch((e) => console.log(e));

// firebase.auth().onAuthStateChanged(async (user) => {
//   let userData;
//   if (user) userData = (await new userFunctions().getUser({ uid: user.uid })).data; // this promise was slow enough to not resolve before the page loads (even though it's a really fast promise)
//   if (!app) app = createApp(App).use(store).use(router).use(vuex).use(elementPlus, { locale: ptBr }).use(ElementPlusIconsVue).mount("#app");
// });
