export const DEBUG = false;

export const MAPBOX_KEY = "pk.eyJ1IjoiZGF0YW1hY2hpbmEiLCJhIjoiY2sxaDZ3bDI2MTRpdTNjbGJka29nNjFwbyJ9.Eef3UvI4IXVSzmQq3Dkdfg";

export const FIREBASE_KEYS = {
  apiKey: "AIzaSyBzJQMf2xD1iMkLIXvgKEvHUdKxHLx0_RQ",
  authDomain: "dm-logistica.firebaseapp.com",
  projectId: "dm-logistica",
  storageBucket: "dm-logistica.appspot.com",
  messagingSenderId: "762413043107",
  appId: "1:762413043107:web:0c838b7c8f89a1a20b5e16",
  measurementId: "G-WBYTECJ4JQ"
};

export const FIREBASE_ORG = "cw4gqK26BVeFReM52XVN";

export const API_URL = "https://apidev.datamachina.com.br";
