<template>
  <footer style="display: none">
    <img src="../../assets/logo_com_fundo.svg" alt="" />
    <p>Data Machina - 2022</p>
    <div class="side-footer">
      Encontrou algo errado?
      <br />
      Envie um e-mail para <b>beta@datamachina.com.br</b>
    </div>
    <div class="side-footer-left">
      <p><small class="text-white">Versão {{commithash}} / {{version}}</small></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "DashFooter",
  mounted() {console.log("process env", process.env)},
  data() {
    return {
      commithash: __COMMIT_HASH__,
      version: process.env.NODE_ENV.toUpperCase(),
    };
  },
};
</script>

<style scoped>
footer {
  left: 0;
  right: 0;
  height: 50px;
  justify-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
  background: #0567ff;
  color: white;
  z-index: 2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

footer img {
  height: 40px;
  margin-right: 30px;
}

.side-footer {
  position: absolute;
  right: 10px;
  text-align: left;
  align-content: center;
  margin-right: 50px;
  font-size: 14px;
}

.side-footer-left {
  position: absolute;
  left: 10px;
  text-align: left;
  align-content: center;
  margin-right: 50px;
  font-size: 14px;
}
</style>
