import { FIREBASE_ORG } from "../../../settings";
import functions from "./index";
const [CREATE, GET, GETALL, CHANGE] = ["create", "get", "getAll", "change"];

export default class {
  constructor() {
    this.functions = functions.httpsCallable("users");
    this.org = FIREBASE_ORG;
  }
  createUser(params) {
    return this.functions({ method: CREATE, org: this.org, params: params });
  }
  getUser(params) {
    return this.functions({ method: GET, org: this.org, params: params });
  }
  getAllUsers(params) {
    return this.functions({ method: GETALL, org: this.org, params: params });
  }
  editUsers(params) {
    return this.functions({ method: CHANGE, org: this.org, params: params });
  }
}
