<template>
  <div>
    <div v-if="orders.length == 0">
      <el-row style="margin-top: 20px; text-align: center" type="flex" justify="center">
        <el-col :span="12">
          <p>A criação de Roteamentos é possível apenas após o cadastro de pedidos.</p>
          <p>Vá para a Aba "Pedidos"</p>
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row style="margin-top: 20px; text-align: center" type="flex" justify="center">
        <el-col :span="20">
          <el-button :disabled="routingButtonDisabled" type="warning" plain text @click="dialogFormVisible = true">
            <el-icon style="vertical-align: middle">
              <MapLocation />
            </el-icon>
            <span style="vertical-align: middle">Criar Roteamento </span>
          </el-button>
        </el-col></el-row
      >
      <el-dialog v-loading="loading_submit_routing" v-model="dialogFormVisible">
        <el-row style="text-align: center" type="flex" justify="space-around">
          <el-col :span="6">
            <span style="text-align: center" slot="title"> <h2>Roteirização</h2></span>
          </el-col>
        </el-row>
        <el-divider content-position="left"><h3>Opções e Restrições</h3></el-divider>
        <el-form :model="form">
          <el-form-item label="Critério de Capacidade">
            <el-radio-group v-model="form.constraints">
              <el-radio disabled label="Quantidade" />
              <el-radio label="Peso" />
              <el-radio disabled label="Volume" />
            </el-radio-group>
          </el-form-item>
          <br />
          <el-divider style="margin-top: 20px" content-position="left"><h3>Local de Saída</h3></el-divider>
          <el-form-item label="Endereço Completo">
            <el-input v-model="form.addressAddress" />
          </el-form-item>
          <el-form-item style="margin-top: 20px" label="Localização">
            <el-col class="text-center" :span="3" style="margin: 0 0.5rem">Latitude</el-col>
            <el-col :span="8">
              <el-input v-model="form.originLatitude" label="Latitude" placeholder="-23.423145" style="width: 100%" />
            </el-col>
            <el-col class="text-center" :span="3" style="margin: 0 0.5rem">Longitude</el-col>
            <el-col :span="8">
              <el-input v-model="form.originLongitude" label="Longitude" placeholder="-46.785343" style="width: 100%" />
            </el-col>
          </el-form-item>
        </el-form>

        <el-row style="margin-top: 20px; margin-bottom: 20px; text-align: center" type="flex" justify="center">
          <el-divider style="margin-top: 20x" content-position="left"><h3>Disponibilidade de Veículos</h3></el-divider>
          <el-col :span="2"></el-col>
          <el-col :span="6">
            <el-select v-model="selected_vehicle" @change="changeCurrentVehicle" placeholder="Selecione um Tipo" style="width: 215px">
              <el-option label="Toco" value="0" />
              <el-option label="Fiorino" value="1" />
              <el-option label="Truck" value="2" />
            </el-select>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="6">
            <el-button :icon="Search" @click="addVehicle">Adicionar Veículo</el-button>
          </el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-table :data="vehicles" border empty-text="Adicione Veículos Para Roteirização" stripe="stripe">
          <el-table-column prop="type" label="Tipo"></el-table-column>
          <el-table-column prop="weight" label="Peso"></el-table-column>
          <el-table-column prop="volume" label="Volume"></el-table-column>
          <el-table-column prop="quantity" label="Quantidade"></el-table-column>
        </el-table>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">Cancelar</el-button>
            <el-button :disabled="sendRoutingButtonDisabled" v-loading="loading_submit_routing" type="primary" plain @click="submitRouting">Enviar</el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <div>
      <div v-if="routes.length > 0">
        <el-row style="margin-top: 20px; text-align: center" type="flex" justify="space-between">
          <el-col :span="8">
            <el-divider content-position="left">Lista de Roteamentos</el-divider>
            <RoutesTable @selected_route_id="onChangeSelectedRouteId" :routes="routes"></RoutesTable>
          </el-col>
          <el-col :span="14" :offset="1">
            <RouteContent :route="selectedRoute"></RouteContent>
          </el-col>
        </el-row>

        <el-row style="margin-top: 20px; text-align: center" type="flex" justify="space-between">
          <el-col :span="24">
            <RouteTripContent :geometries="geometries" :route="selectedRoute" :vehicle_trips="vehicle_trips"></RouteTripContent>
            <!-- <MapContent :geometries="geometries" :route="selectedRoute" /> -->
          </el-col>
        </el-row>
      </div>

      <div v-else style="height: 400px">
        <div v-if="orders.length > 0">
          <el-row style="margin-top: 100px; text-align: center" type="flex" justify="center">
            <el-col :span="10">
              <h3>Não existem <i> Roteirizações </i>cadastradas.</h3></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoutesTable from "./RoutesTable.vue";
import RouteContent from "./RouteContent.vue";
import RouteTripContent from "./RouteTripContent.vue";
import { MapLocation } from "@element-plus/icons-vue";
import { createRouting } from "@/helpers/itineraries";

export default {
  name: "RoutingPane",
  components: { RoutesTable, RouteContent, RouteTripContent, MapLocation },
  props: ["orders", "routes", "itinerary_id", "user_id"],
  data() {
    return {
      selected_vehicle: null,
      loading_submit_routing: false,
      fileList: [],
      vehicle_types: [
        { id: "0", type: "Toco", weight: 1000, volume: 36, quantity: 710 },
        { id: "1", type: "Fiorino", weight: 500, volume: 2.16, quantity: 58 },
        { id: "2", type: "Truck", weight: 2500, volume: 46, quantity: 1188 },
      ],
      vehicles: [],
      selectedRoute: null,
      dialogFormVisible: false,
      form: {
        constraints: "Peso",
        addressAddress: "Av. Lauro de Gusmão Silveira, 849 - Jardim Sao Geraldo, Guarulhos - SP, 07140-010",
        originLatitude: -23.43908444581003,
        originLongitude: -46.49867995211551,
      },
    };
  },
  computed: {
    routingButtonDisabled() {
      return this.orders.length == 0;
    },
    sendRoutingButtonDisabled() {
      return this.vehicles.length == 0;
    },
    enrichedRoutes() {
      let self = this;
      console.log(this.routes);
      if (!this.selectedRoute) return [];
      console.log(this.selectedRoute);
      return Object.keys(this.selectedRoute.enriched_routes).map((vehicle_key) => {
        let vehicle_trip = self.selectedRoute.enriched_routes[vehicle_key];
        let vehicle = [];
        vehicle_trip.map(function (stop) {
          vehicle.push({ addressLatitude: stop.location[0], addressLongitude: stop.location[1] });
        });
        return vehicle;
      });
    },
    vehicle_trips() {
      return this.enrichedRoutes.map(function (vehicle_trip) {
        return vehicle_trip.map((node) => {
          return [node.addressLatitude, node.addressLongitude];
        });
      });
    },
    geometries() {
      return this.vehicle_trips.map(function (trip) {
        // trip.shift(); // FIXME: replace first point with actual origin
        return trip.map((node) => `${node[1]},${node[0]}`).join(";");
      });
    },
  },
  async mounted() {
    console.log("enriched routes", this.enrichedRoutes);
  },
  methods: {
    changeCurrentVehicle(vehicle) {
      console.log(vehicle);
      this.selected_vehicle = vehicle;
    },
    addVehicle() {
      if (this.selected_vehicle == null || this.selected_vehicle < 0 || this.selected_vehicle > this.vehicle_types.length) {
        alert("Selecione um veículo")
        return;
      }

      console.log(this.selected_vehicle,this.vehicle_types.length, this.selected_vehicle > this.vehicle_types.length)
      this.vehicles.push(this.vehicle_types[this.selected_vehicle]);
    },
    onChangeSelectedRouteId(route_id) {
      this.selectedRoute = this.routes.filter((route) => route.id === route_id)[0];
    },
    submitRouting() {
      let self = this;
      console.log("submit");
      this.loading_submit_routing = true;
      setTimeout(async function () {
        let r = await createRouting(self.user_id, self.itinerary_id, [self.form.originLatitude, self.form.originLongitude], self.vehicles);
        console.log(r);
        self.loading_submit_routing = false;
        self.dialogFormVisible = false;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.icon {
  height: 50px;
}
.container {
  margin: 0 50px 0 50px;
}

.inputs {
  margin-top: 50px;
}

.tables {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: 50px 0 50px 0;
}

h1 {
  margin: 50px 0 50px 0;
}

.barraR {
  color: #0567ff;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  /* margin-left: 20px; */
}

.ibge-tooltip {
  position: absolute;
  border: 1px solid #f45197;
  border-radius: 20px;
  width: 10px;
  top: -20px;
  color: #f45197;
}
.ibge-tooltip:focus,
.ibge-tooltip:hover {
  color: #f45197;
  border-color: #f45197;
}

.ranking-stores-table,
.ranking-regionals-table,
.ranking-clusters-table {
  /* border-radius: 15px; */
  text-align: left !important;
}

.el-input,
.el-autocomplete {
  --el-border-color-base: #0567ff;
  --el-border-base: var(--el-border-width-base) var(--el-border-style-base) var(--el-border-color-base);
}
</style>

<style>
.el-popover.el-popper {
  font-family: "Roboto", sans-serif;
  width: 200px !important;
}
/* Font-family Calender e Search */
.el-autocomplete-suggestion li {
  font-family: "Roboto", sans-serif;
}
.el-picker-panel__body {
  font-family: "Roboto", sans-serif;
}
/* inputs */
.ranking-search {
  width: 100%;
}

.ranking-date-editor {
  border: 1px solid #0567ff !important;
}

/* tables */
.ranking-table {
  --el-table-border-color: #959595 !important;
}

.ranking-table .header .cell {
  color: #ffffff !important;
  width: 92% !important;
}

.ranking-table .cell {
  word-break: inherit !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  margin-left: 10px;
}

.ranking-table td.el-table__cell div {
  color: #2c2c2c !important;
}

.ranking-stores-table .header {
  background-color: #0567ff !important;
}
.ranking-regionals-table .header {
  background-color: #f45197 !important;
}
.ranking-clusters-table .header {
  background-color: #3e9a00 !important;
}
html::-webkit-scrollbar {
  width: 12px;
  background: rgb(250, 246, 240, 0.25);
}
html::-webkit-scrollbar-thumb {
  background-color: #cacaca;
  border-radius: 20px;
  border: 4px solid #fdfbf9;
}
</style>
