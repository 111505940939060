<template>
  <div class="container">
    <el-row class="map__outer" type="flex" justify="center">
      <el-col :span="24">
        <div style="border-radius: 10px" v-loading="loadingMap" id="map" />
      </el-col>
    </el-row>    
  </div>

</template>

<script>
import { Loading } from "@element-plus/icons-vue";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_KEY } from "../../../settings.js";
const axios = require("axios");

// import MapboxDirections from "mapbox-gl/src/"
// import "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";

export default {
  name: "MapContent",
  components: { Loading },
  props: ["geometries", "route"],
  data() {
    return { loadingMap: false, map: null };
  },
  computed: {
    routes_per_vehicle() {
      if (this.route) {
        console.log(
          "routes per vehicle",
          Object.keys(this.route.result.routes_per_vehicle_id).map((key) => this.route.result.routes_per_vehicle_id[key])
        );
        return Object.keys(this.route.result.routes_per_vehicle_id).map((key) => this.route.result.routes_per_vehicle_id[key]);
      } else return [];
    },
  },
  watch: {
    geometries(old, val) {
      let self = this;
      // force map resize so it fills entire container
      
      console.log('watching geometries', this.geometries)

      // fetch directions from mapbox directions api
      self.geometries.map(function (geo, i) {
        axios
          .get(`https://api.mapbox.com/directions/v5/mapbox/driving/${geo}?access_token=${MAPBOX_KEY}&geometries=geojson`)
          .then(function (response) {
            const json = response.data; //await query.json();
            const data = json.routes[0];
            const route = data.geometry.coordinates;
            const geojson = {
              type: "Feature",
              properties: { color: "#" + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0") },
              geometry: {
                type: "LineString",
                coordinates: route,
              },
            };
            // if the route already exists on the map, we'll reset it using setData
            if (self.map.getSource(`route-${i}`)) {
              self.map.getSource(`route-${i}`).setData(geojson);
            }
            // otherwise, we'll make a new request
            else {
              self.map.addLayer({
                id: `route-${i}`,
                type: "line",
                source: {
                  type: "geojson",
                  data: geojson,
                },
                layout: {
                  "line-join": "round",
                  "line-cap": "round",
                },
                paint: {
                  //   "line-color": "#3887be",
                  "line-color": ["get", "color"],
                  "line-width": 5,
                  "line-opacity": 0.75,
                },
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
  },
  async mounted() {
    let self = this;
    mapboxgl.accessToken = MAPBOX_KEY;

    self.loadingMap = true;
    self.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/datamachina/ck8iw6wq915dd1intj8cl59um",
      center: [-46.1693136, -23.9128697],
      zoom: 7,
    });
    this.map.on("error", (e) => {
      console.log("error", e);
    });
    this.map.on("load", () => {
      self.loadingMap = false;
      if (self.geometries.length > 0) {
      } else {
        console.log("no geometries");
      }

      setTimeout(()=> self.map.resize(),2000)
    });
  },
  methods: {},
};
</script>

<style>
.el-table .selected-row {
  background: rgb(232, 232, 247);
}

.map__outer {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

#map {
  overflow: hidden;
  position: relative;
  z-index: 0;
  /* width: 100vw; */
  /* height: 100vh; */
  /* aspect-ratio: 1 / 1; */
  min-height: 400px;
  max-height: 400px;
}
</style>
