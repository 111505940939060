<template>
  <div class="container">
    <el-row type="flex" justify="center">
      <el-col :span="24"> </el-col>
      <!-- // id, operation, addressCountry, addressState, addressCity, addressZip, addressAddress, addressLatitude, addressLongitude -->
      <el-table
        :data="routes"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        @row-click="onRowClick"
        row-key="id"
        size="small"
        @current-change="handleCurrentChange"
      >
        <el-table-column prop="status" label="Status">
          <template style="text-align: center" #default="scope">          
            <div style="text-align: center" v-if="(scope.row.status == 'processing')">
              <el-icon :size="28" style="vertical-align: middle" color="red" class="is-loading">
                <Loading />
              </el-icon>
            </div>
            <div v-else style="text-align: center">
              <div style="text-align: center" v-if="scope.row.status == 'finished'">
                <el-icon :size="28" style="vertical-align: middle" color="green">
                  <CircleCheckFilled />
                </el-icon>
              </div>
              <div v-else>
                <el-icon :size="28" style="vertical-align: middle" color="red">
                  <CircleClose />
                </el-icon>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="120"/>
        <el-table-column prop="created_ts" label="Date" width="120">
          <template #default="scope">
            {{ new Date(scope.row.created_ts.seconds * 1000).toLocaleString() }}
          </template>
        </el-table-column>
        <!-- add configuration parameters as column items -->
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { Loading, CircleCheckFilled, CircleClose } from "@element-plus/icons-vue";
export default {
  name: "RoutesTable",
  components: { Loading, CircleCheckFilled, CircleClose },
  props: ["routes"],
  data() {
    return { currentRowID: "" };
  },
  async mounted() {},
  methods: {
    onRowClick(row, column, event) {
      this.currentRowID = row.id;
    },
    handleCurrentChange(val) {
      this.$emit("selected_route_id", val.id);
      console.log("selected row", val);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.id === this.currentRowID) {
        return "selected-row";
      } else {
        // return "success-row";
      }
    },
  },
};
</script>

<style>
.el-table .selected-row {
  background: rgb(225, 225, 226);
}
</style>
