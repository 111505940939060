<template>
  <div class="container">
    <el-row type="flex" justify="start">
      <!-- <el-col :span="2" /> -->
      <el-col :span="4">
        <h2><b class="barraR">/</b>Itinerário {{ itinerary_id }}</h2>
      </el-col>
    </el-row>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/itinerary' }">/itinerários</el-breadcrumb-item>
      <el-breadcrumb-item> {{ itinerary_id }}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-row style="margin-top: 20px; text-align: center" type="flex" justify="center">
      <el-col :span="24">
        <el-tabs type="border-card" class="demo-tabs">
          <el-tab-pane label="Pedidos">
            <template #label>
              <span class="custom-tabs-label">
                <el-icon><ShoppingCart /></el-icon>
                <span> Pedidos</span>
              </span>
            </template>
            <OrdersPane :orders="orders" :itinerary_id="itinerary_id" :user_id="user_id"></OrdersPane>
          </el-tab-pane>

          <el-tab-pane label="Roteamentos">
            <template #label>
              <span class="custom-tabs-label">
                <el-icon><Reading /></el-icon>
                <span> Roteamentos</span>
              </span>
            </template>
            <RoutingPane :orders="orders" :routes="routes" :itinerary_id="itinerary_id" :user_id="user_id"></RoutingPane>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>

    <el-row style="margin-top: 10px" type="flex" justify="space-around">
      <el-col :span="10">
      <a href="/orders_example.csv" download>Exemplo de arquivo CSV</a>
        <a href=""></a>
        <br />        
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrdersPane from "./OrdersPane.vue";
import RoutingPane from "./RoutingPane.vue";

import { ShoppingCart, Reading, DeleteFilled, MapLocation } from "@element-plus/icons-vue";

import firebase from "firebase";

export default {
  name: "Itinerary",
  components: { OrdersPane, RoutingPane, ShoppingCart, Reading, DeleteFilled, MapLocation },
  props: {},
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser", "token"]),
    finishedLoading() {
      return this.loading === 0;
    },
  },
  data() {
    return {
      loadingOrders: false,
      loadingRoutes: false,
      itinerary_id: "",
      user_id: "",
      fileList: [],
      orders: [],
      routes: [],
      origin: "",
      dialogFormVisible: false,
      myData: [
        { id: "0", type: "Toco", weight: 6500, volume: 26.4, quantity: 713 },
        { id: "1", type: "Truck", weight: 12000, volume: 44.0, quantity: 1188 },
        { id: "2", type: "Fiorino", weight: 650, volume: 2.16, quantity: 58 },
      ],
      form: {
        constraints: "Quantidade",
        addressAddress: "Av. Lauro de Gusmão Silveira, 849 - Jardim Sao Geraldo, Guarulhos - SP, 07140-010",
        originLatitude: -23.43908444581003,
        originLongitude: -46.49867995211551,
      },
    };
  },
  async created() {
    const self = this;
    document.title = "Itinerary";
  },
  async mounted() {
    let self = this;
    this.itinerary_id = this.$route.params.id;
    this.user_id = this.currentUser.uid;

    self.loadingOrders = true;
    firebase
      .firestore()
      .collection(`users/${this.user_id}/itineraries/${this.itinerary_id}/orders`)
      .onSnapshot(
        function (snapshot) {
          self.loadingOrders = false;

          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {             
              // push document to array
              self.orders.push({ ...change.doc.data(), id: change.doc.id });
            }
            if (change.type === "modified") {             
              // find and update document
              let index = self.orders.map(function(e) { return e.id; }).indexOf(change.doc.id);
              self.orders[index] = { ...change.doc.data(), id: change.doc.id}              
            }
            if (change.type === "removed") {             
              // find and remove document
              let index = self.orders.map(function(e) { return e.id; }).indexOf(change.doc.id);
              self.orders.splice(index,1)
            }
          });

        },
        function (error) {
          console.log("handle onsnapshot error", error);
        }
      );

    self.loadingRoutes = true;
    firebase
      .firestore()
      .collection(`users/${this.user_id}/itineraries/${this.itinerary_id}/routes`)
      .onSnapshot(function (snapshot) {
        console.log(snapshot);
        self.loadingRoutes = false;
        if (snapshot.empty) {
          self.routes = [];
          return;
        }
        console.log("routes", snapshot.docs);
        self.routes = [];
        snapshot.docs.map(function (doc) {
          self.routes.push({ ...doc.data(), id: doc.id });
        });
      });
  },
  methods: {},
};
</script>

<style scoped>
.container {
  margin: 0 20px 0 20px;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  /* margin-left: 20px; */
}
</style>
