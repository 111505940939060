<template>
  <div id="nav">
    <router-view />
    <DashSidebar />
    <DashFooter />
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import DashFooter from "./components/ui/DashFooter.vue";
import DashSidebar from "./components/ui/DashSidebar.vue";

export default {
  name: "Login",
  components: { DashFooter, DashSidebar },
  data() {
    return { dateRange: "DATE_RANGE" };
  },
  computed: {
    ...mapGetters("auth", ["isLoggedIn", "currentUser"]),
  },
  created() {
    document.title = `Painel Logística`;
    // redirect user to painel if already logged in
    // if (!this.isLoggedIn) this.$router.push("/");
  },
  methods: {
    itineraries() {
      this.$router.push("/itinerary");
    },
    logout() {
      var self = this;
      firebase
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          self.$store.dispatch("auth/logout");
          self.$router.push("/login");
        })
        .catch(function (error) {
          // An error happened
          alert("Erro ao tentar sair: ", error);
        });
    },
  },
};
</script>

<style>
#app,
.el-dropdown-menu,
.table-select-options span {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c2c2c;
}

router-view {
  min-width: 1200px;
}

#nav {
  padding: 0px 0px 20px 12px;
  margin-left: 40px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

* {
  font-family: "Roboto", sans-serif;
  --el-text-color-placeholder: rgba(0, 0, 0, 0.6);
  --el-border-radius-base: 10px;
}

.el-scrollbar {
  --el-scrollbar-opacity: 0.8 !important;
  --el-scrollbar-bg-color: #918f8f !important;
  --el-scrollbar-hover-opacity: 0.8 !important;
  --el-scrollbar-hover-bg-color: #918f8f !important;
}

.el-popper {
  word-break: break-word !important;
}
</style>
