import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/functions";
import "@firebase/analytics";

import { FIREBASE_KEYS } from "../../settings";

firebase.initializeApp(FIREBASE_KEYS);
firebase.analytics();

// If on localhost, use all firebase services locally
if (location.hostname === "localhost") {
  // db.useEmulator("localhost", 8080);
  // auth.useEmulator("http://localhost:9099");
  // firebase.functions().useEmulator("localhost", 5001);
}

export default firebase;
