<template>
  <div>
    <div v-if="route !== null">
      <el-row style="margin-top: 20px; text-align: center" type="flex" justify="space-between">
        <el-divider style="margin-top: 20px;" content-position="left">Viagens do Roteamento {{ route.id }}</el-divider>

        <el-col style="overflow: hidden" :span="12">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item v-for="key in Object.keys(route.enriched_routes)" :name="key">
              <template #title>
                Viagem Veículo: {{ key
                }}<el-icon class="header-icon">
                  <info-filled />
                </el-icon>
              </template>
              {{ route_for_vehicle(key) }}
            </el-collapse-item>
          </el-collapse>

          <!-- {{ route }} -->
        </el-col>

        <el-col :span="12">
          <MapContent :geometries="geometries" :route="route" />
        </el-col>
      </el-row>
    </div>
    <div v-else>
      <el-row style="margin-top: 20px; text-align: center" type="flex" justify="space-between">
        <el-divider content-position="left">Viagens do Roteamento </el-divider>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Loading } from "@element-plus/icons-vue";
import MapContent from "./MapContent.vue";

export default {
  name: "RouteTripContent",
  components: { Loading, MapContent },
  props: ["geometries", "route", "vehicle_trips"],
  data() {
    return { currentRowID: "" };
  },
  computed: {},
  watch: {
    route: {
      handler(old, n) {
        console.log("wachting", this.route);
      },
      deep: true,
    },
    geometries(old, val) {
      let self = this;
      // force map resize so it fills entire container
      console.log("watching geometries routetripcontent", this.geometries);
    },
  },
  async mounted() {
    console.log("routetripcontent", this.route);
  },
  methods: {
    route_for_vehicle(vehicle_key) {
      console.log(vehicle_key, this.route.enriched_routes);
      if (this.route) {
        console.log(this.route.enriched_routes);
        return this.route.enriched_routes[vehicle_key];
      } else {
        return "vazio";
      }
    },
    onRowClick(row, column, event) {
      this.currentRowID = row.id;
    },
    handleCurrentChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.id === this.currentRowID) {
        return "selected-row";
      } else {
        // return "success-row";
      }
    },
  },
};
</script>

<style>
.el-table .selected-row {
  background: rgb(239, 239, 255);
}
</style>
