import { init } from "../plugins/localStorage";
import firebase from "firebase";

const state = {
  token: init,
  user: null,
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  resetToken(state) {
    state.token = "";
  },
  setUser(state, user) {
    state.user = user;
  },
  resetUser(state) {
    state.user = null;
  },
};

const actions = {
  async login({ commit }, user) {
    if (user) {
      const idToken = await firebase.auth().currentUser.getIdToken();
      commit("setToken", idToken);
      commit("setUser", user);
    } else {
    }
  },
  logout({ commit }) {
    commit("resetToken");
    commit("resetUser");
  },
};

const getPayload = ({ token }) => {
  const payload = token.split(".")[1];
  return JSON.parse(atob(payload));
};

const getters = {
  token(state) {
    return state.token;
  },
  isLoggedIn(state) {
    if (!state.token) return false;
    let payload = getPayload(state);
    return payload.exp > Date.now() / 1000;
  },
  currentUser(state) {
    if (!state.token) return {};
    if (!state.user) return {};
    const name = state.user.displayName;
    const email = state.user.email;
    const uid = state.user.uid;
    const isMember = state.user.roles ? true : false;
    const isCreator = state.user.creator || false;
    const isAdmin = (state.user.roles || {}).admin || isCreator;
    return { name, email, uid, isMember, isCreator, isAdmin };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
