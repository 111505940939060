<template>
  <div>
    <router-view />
    
    <DashSidebar />
    <DashFooter />
  </div>
</template>

<script>
import DashFooter from "@/components/ui/DashFooter.vue";
import DashSidebar from "@/components/ui/DashSidebar.vue";

export default {
  name: "EmptyRouterView",
  components: { DashFooter, DashSidebar },
  mounted() {},
};
</script>
