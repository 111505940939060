<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="24">
        <el-divider v-if="route !== null" content-position="left">Resumo do Roteamento: <b>{{ route.id }}</b> </el-divider>
        <el-divider v-else content-position="left">Resumo do Roteamento </el-divider>
        <div v-if="route !== null" class="container">
          <el-row type="flex" justify="center">
            <el-col :span="24">
              Total de Viagens (veículos): {{ routes_per_vehicle.length }}
              <ul>
                <li v-for="(vehicle_route, i) in routes_per_vehicle">
                  <p>Viagem {{ i }}</p>
                  Locais visitados: {{ vehicle_route.length }} // Distância: {{ vehicle_route.reduce((a, b) => a + b.distance, 0) }}Km // Carregamento:
                  {{ vehicle_route.reduce((a, b) => a + b.load, 0) }}
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
        <div v-else>
          <br />
          <p>Selecione um roteamento ao lado para visualização</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Loading } from "@element-plus/icons-vue";
export default {
  name: "RouteContent",
  components: { Loading },
  props: ["route"],
  data() {
    return { currentRowID: "" };
  },
  computed: {
    routes_per_vehicle() {
      if (this.route) {
        console.log(
          "routes per vehicle",
          Object.keys(this.route.result.routes_per_vehicle_id).map((key) => this.route.result.routes_per_vehicle_id[key])
        );
        return Object.keys(this.route.result.routes_per_vehicle_id).map((key) => this.route.result.routes_per_vehicle_id[key]);
      } else return [];
    },
  },
  async mounted() {},
  methods: {
    onRowClick(row, column, event) {
      this.currentRowID = row.id;
    },
    handleCurrentChange(val) {
      console.log(val);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.id === this.currentRowID) {
        return "selected-row";
      } else {
        // return "success-row";
      }
    },
  },
};
</script>

<style>
.el-table .selected-row {
  background: rgb(239, 239, 255);
}
</style>
