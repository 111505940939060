<template>
  <div class="container">
    <el-row type="flex" justify="center">
      <el-col :span="24"> </el-col>
      <!-- // id, operation, addressCountry, addressState, addressCity, addressZip, addressAddress, addressLatitude, addressLongitude -->
      <el-table :data="orders" border style="width: 100%" :row-class-name="tableRowClassName">
        <el-table-column prop="geocoding" label="-" width="60">
          <template style="text-align: center" #default="scope">
            <div style="text-align: center" v-if="(scope.row.geocoding && scope.row.geocoding.status == 'loading') || !scope.row.geocoding">
              <el-icon :size="28" style="vertical-align: middle" color="red" class="is-loading">
                <Loading/>
              </el-icon>
            </div>
            <div v-else style="text-align: center">
              <div style="text-align: center" v-if="scope.row.geocoding.status == 'ok'">
                <el-icon :size="28" style="vertical-align: middle" color="green">
                  <CircleCheckFilled />
                </el-icon>
              </div>
              <div v-else>
                <el-icon style="vertical-align: middle" color="green">
                  <CircleClose />
                </el-icon>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="120" />
        <el-table-column prop="weight" label="Peso" />
        <el-table-column prop="volume" label="Volume" />
        <el-table-column prop="quantity" label="Qtd" />
        <el-table-column prop="addressAddress" label="Destino" width="200" />
        <el-table-column label="Location">
          <template #default="scope">
            {{ scope.row.addressLatitude + "," + scope.row.addressLongitude }}
          </template>
        </el-table-column>
        <el-table-column label="Ações">
          <template #default="scope">
            <el-button :disabled=true type="primary" circle plain>
              <el-icon style="vertical-align: middle">
                <Edit />
              </el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>
import { Edit, AlarmClock, Search, Upload, Loading, CircleCheckFilled, CircleClose } from "@element-plus/icons-vue";
export default {
  name: "OrdersTable",
  components: { Edit, AlarmClock, Search, Upload, Loading, CircleCheckFilled, CircleClose },
  props: ["orders"],
  data() {
    return {};
  },
  async mounted() {},
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (!row.geocoding) {
        return "warning-row";
      }
      if (row.geocoding.status != "ok") {
        return "warning-row";
      }
    },
  },
};
</script>

<style>
.el-table .warning-row {
  background: rgb(255, 205, 205);
}

.el-table .success-row {
  background: #f0f9eb;
}

.container {
  margin: 0 50px 0 50px;
}

h1 {
  margin: 50px 0 50px 0;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  /* margin-left: 20px; */
}

.el-icon-loading {
  font-size: 30px;
}

</style>
