const axios = require("axios");
import config from "@/helpers/config"

const createItinerary = async (uid, accessToken) => {
  let response;
  try {
    let endpoint = config.itinerariesApiUrl    
    console.log('endpoint', endpoint)
    response = await axios.post(
      `${endpoint}`,
      {
        uid: uid,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    return { error: e };
  }
};

const createRouting = async (user_id, itinerary_id, origin, vehicles_and_capacities, accessToken) => {
  let response;
  try {
    let endpoint = config.itinerariesApiUrl    
    response = await axios.post(
      `${endpoint}/createRouting`,
      {
        user_id: user_id,
        itinerary_id: itinerary_id,
        origin: JSON.stringify(origin),
        vehicles_and_capacities: JSON.stringify(vehicles_and_capacities)
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    return { error: e };
  }
};

const loadOrdersFromCSV = async (user_id, itinerary_id, file, accessToken) => {
  let response;
  let endpoint = config.itinerariesApiUrl    
  try {
    console.log("loadOrdersFromCSV", `${endpoint}/loadOrders`);

    console.log("user_id", `${user_id}`);
    console.log("itinerary_id", `${itinerary_id}`);
    console.log("file", `${file}`);
    var formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", user_id);
    formData.append("itinerary_id", itinerary_id);
    response = await axios.post(
      `${endpoint}/loadOrders`,formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log("e loadorders", e);
    return { error: e };
  }
};

export { createItinerary, createRouting, loadOrdersFromCSV };
