<template>
  <div  style="display: none" v-if="$route.name && $route.name !== 'Login' && $route.name !== 'Painel'" class="sidebar">
    
    <div class="sidebar-div">
         <img class="logo" src="../../assets/logoMachina.svg"/> 

    </div>
   
    <!-- Botão para ir ao mapa -->
    <div class="sidebar-div">
      <el-tooltip popper-class="box-item" effect="light" content="Mapa" placement="right">
        <el-button class="sidebar-button" @click="itineraries" type="text" size="small">
          <img class="sidebar-icon" src="../../assets/sidebar/mapaCamadas.svg" alt="" />
        </el-button>
      </el-tooltip>
    </div>

    <!-- Botão de lougout -->
    <div class="sidebar-div">
      <el-tooltip popper-class="box-item" effect="light" content="Sair" placement="right">
        <el-button class="sidebar-button" @click="logout" type="text" size="small">
          <img class="sidebar-icon" src="../../assets/sidebar/logout.png" alt="" />
        </el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashSidebar",
  methods: {
    collaboratorsRedirect() {
      this.$root.collaborators();
    },
    itineraries() {
      console.log('map', this.$root.itineraries)
      this.$root.itineraries()
    },
    logout() {
      this.$root.logout();
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  text-align: center;
  top: 0;
  left: 0;
  background: #0567ff;
  color: white;
  width: 60px;
  height: 100%;
  z-index: 1;
}
.sidebar-div {
  margin-top: 30px;
}
.sidebar-button {
  cursor: pointer;
  width: 45px;
  height: 45px;
}
.sidebar-button:hover,
.sidebar-button:focus,
.clicked {
  border-radius: 20px;
  background-color: #accdff;
}
.sidebar-button:hover {
  opacity: 0.5;
}
.sidebar-icon {
  height: 30px;
  width: 30px;
}
.logo{
  width: 45px;
}

</style>

<style>
.box-item {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding: 10px 10px 10px 15px;
}
</style>
