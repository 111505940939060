<template>
  <div>
    <el-row style="margin-top: 20px; text-align: center" type="flex" justify="space-between">
      <el-col :span="3">
        <el-upload
          ref="upload"
          action=""
          :http-request="uploadSectionFile"
          :auto-upload="true"
          :file-list="fileList"
          :on-error="uploadFileError"
          :on-success="uploadFileSuccess"
          :on-exceed="exceedFile"
          :on-remove="removeFile"
        >
            <el-button disabled slot="trigger" type="primary" plain>
              <el-icon style="vertical-align: middle">
                <Files />
              </el-icon>
              <span style="vertical-align: middle"> Importar Pedidos </span>
            </el-button>

        </el-upload>
      </el-col>

      <el-col :span="3">
        <el-button :disabled="true" type="danger" plain>
          <el-icon style="vertical-align: middle">
            <Files />
          </el-icon>
          <span style="vertical-align: middle"> Limpar Dados </span>
        </el-button>
      </el-col>
    </el-row>

    <el-row v-if="orders.length != 0" style="margin-top: 20px; text-align: center" type="flex" justify="center">
      <el-col :span="24">
        <div v-if="!geocodingCompleted">
          <p>
            <el-icon color="#FF0000" style="vertical-align: middle">
              <WarningFilled />
            </el-icon>
            {{ orders.length - geocodedOrders }} de {{ geocodedOrders }}
            <el-icon color="#FF0000" style="vertical-align: middle">
              <WarningFilled />
            </el-icon>
            endereços geo-localizados
          </p>
          <p>Corrija os endereços indicados abaixo antes de prosseguir</p>
        </div>
        <div v-else>Todos os endeços estão geo-localizados - <b>verifique</b> a corretude dos mesmos e atualize se necessário</div>
      </el-col>
    </el-row>

    <el-row style="margin-top: 20px; text-align: center" type="flex" justify="center">
      <el-col :span="24">
        <div v-if="orders.length == 0">
          <div v-if="loadingFile">
            <el-skeleton :rows="5" animated />
          </div>
          <div v-else>
            <p>Ainda não existem pedidos para este Itinerário</p>
            <el-upload
              drag
              ref="upload"
              action=""
              :http-request="uploadSectionFile"
              :auto-upload="true"
              :file-list="fileList"
              :on-error="uploadFileError"
              :on-success="uploadFileSuccess"
              :on-exceed="exceedFile"
              :on-remove="removeFile"
            >
              <el-icon :size="100" style="vertical-align: middle">
                <UploadFilled />
              </el-icon>
              <div class="el-upload__text">Arraste o arquivo CSV aqui ou <em>clique para escolher</em></div>
              <div class="el-upload__tip" slot="tip">arquivos csv menores de 1Mb</div>
            </el-upload>
          </div>
        </div>
        <div v-else>
          <OrdersTable :orders="orders"></OrdersTable>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Search, DeleteFilled, Files, UploadFilled, WarningFilled } from "@element-plus/icons-vue";
import OrdersTable from "./OrdersTable.vue";
import { loadOrdersFromCSV } from "@/helpers/itineraries";

export default {
  name: "OrdersPane",
  components: { OrdersTable, Search, DeleteFilled, Files, UploadFilled, WarningFilled },
  props: ["orders", "itinerary_id", "user_id"],
  data() {
    return { fileList: [], loadingFile: false };
  },
  computed: {
    geocodingCompleted() {
      let geocoded_ok = this.orders
        .map((i) => {
          return i.geocoding ? i.geocoding.status == "ok" : false;
          // return i.geocoding.status == "ok";
        })
        .filter((i) => {
          return i;
        });
      return this.orders.length == geocoded_ok.length;
    },
    geocodedOrders() {
      let geocoded_ok = this.orders
        .map((i) => (i.geocoding ? i.geocoding.status == "ok" : false))
        .filter((i) => {
          return i;
        });
      console.log(geocoded_ok, geocoded_ok.length);
      return geocoded_ok.length;
    },
  },
  async mounted() {},
  methods: {
    async uploadSectionFile(param) {
      try {
        this.fileList = [];
        var fileObj = param.file;
        this.loadingFile = true;
        let fileValid = await loadOrdersFromCSV(this.user_id, this.itinerary_id, fileObj);
        this.loadingFile = false;
      } catch (e) {
        console.log(e);
        this.loadingFile = false;
        this.$message.error("upload failed!");
      }
    },
    //upload failed
    uploadFileError(err, file, fileList) {
      console.log(err);
      this.$message.error("upload failed!");
    },
    //Uploaded successfully
    uploadFileSuccess(response, file, fileList) {
      console.log(response);
      if (response.data.error == 0) {
        console.log(response.data);
        file.response = response.data.data;
        this.fileList.push(file);
      } else {
        this.$message.error(response.data.message); //File upload error prompt
      }
    },
    // Hook when the file exceeds the limit
    exceedFile(files, fileList) {
      console.log("error");
      this.$message.error("Only upload" + this.limitnum + "Files");
    },
    //Delete Files
    removeFile(file, fileList) {
      var self = this;
      this.tableData = [];
      if (self.directionsDisplay != null) {
        self.directionsDisplay.setMap(null);
        self.directionsDisplay = null;
        self.directionsService = null;
      }
      this.fileList = fileList;
    },
  },
};
</script>

<style scoped>
.icon {
  height: 50px;
}
.container {
  margin: 0 50px 0 50px;
}

.inputs {
  margin-top: 50px;
}

.tables {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin: 50px 0 50px 0;
}

h1 {
  margin: 50px 0 50px 0;
}

.barraR {
  color: #0567ff;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  /* margin-left: 20px; */
}

.ibge-tooltip {
  position: absolute;
  border: 1px solid #f45197;
  border-radius: 20px;
  width: 10px;
  top: -20px;
  color: #f45197;
}
.ibge-tooltip:focus,
.ibge-tooltip:hover {
  color: #f45197;
  border-color: #f45197;
}

.ranking-stores-table,
.ranking-regionals-table,
.ranking-clusters-table {
  /* border-radius: 15px; */
  text-align: left !important;
}

.el-input,
.el-autocomplete {
  --el-border-color-base: #0567ff;
  --el-border-base: var(--el-border-width-base) var(--el-border-style-base) var(--el-border-color-base);
}
</style>

<style>
.el-popover.el-popper {
  font-family: "Roboto", sans-serif;
  width: 200px !important;
}
/* Font-family Calender e Search */
.el-autocomplete-suggestion li {
  font-family: "Roboto", sans-serif;
}
.el-picker-panel__body {
  font-family: "Roboto", sans-serif;
}
/* inputs */
.ranking-search {
  width: 100%;
}

.ranking-date-editor {
  border: 1px solid #0567ff !important;
}

/* tables */
.ranking-table {
  --el-table-border-color: #959595 !important;
}

.ranking-table .header .cell {
  color: #ffffff !important;
  width: 92% !important;
}

.ranking-table .cell {
  word-break: inherit !important;
  font-size: 16px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  margin-left: 10px;
}

.ranking-table td.el-table__cell div {
  color: #2c2c2c !important;
}

.ranking-stores-table .header {
  background-color: #0567ff !important;
}
.ranking-regionals-table .header {
  background-color: #f45197 !important;
}
.ranking-clusters-table .header {
  background-color: #3e9a00 !important;
}
html::-webkit-scrollbar {
  width: 12px;
  background: rgb(250, 246, 240, 0.25);
}
html::-webkit-scrollbar-thumb {
  background-color: #cacaca;
  border-radius: 20px;
  border: 4px solid #fdfbf9;
}
</style>
